.Toolbar {
    width: 100%;
    height: 56px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: green;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 90;
}

.Toolbar nav {
    height: 100%;

}

@media (max-width:499px) {

    .Toolbar nav {
        display: none;
    }
}

.DrawerToggle {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 500px) {
    .DrawerToggle {
        display: none;
    }
}