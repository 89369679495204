.BreadTop {
    height: 20%;
    width: 80%;
    background-image: url(../../../assets/images/bread-top.png);
    border-radius: 50% 50% 0 0;
    margin: 1px auto;
}

.BreadBottom {
    height: 13%;
    width: 80%;
    background-image: url(../../../assets/images/bread-buttom.png);
    border-radius: 0 0 30px 30px;
    margin: 1px auto;

}



.Meat {
    width: 90%;
    height: 6%;
    background: linear-gradient(#7f3608, #702e05);
    background-image: url(../../../assets/images/meat.png);
    border-radius: 15px;
    margin: 1px auto;
}

.Cheese {
    width: 75%;
    height: 4.5%;
    background: linear-gradient(#f4d004, #d6bb22);
    background-image: url(../../../assets/images/cheese.png);
    border-radius: 5px;
    margin: 1px auto;
}

.Salad {
    min-height: 0;
    width: 85%;
    height: 5%;
    background: linear-gradient(#228c1d, #91ce50);
    border-radius: 20px;
    margin: 1px auto;
}

.Bacon {
    min-height: 0;
    width: 95%;
    height: 3%;
    background: linear-gradient(#bf3813, #c45e38);
    background-image: url(../../../assets/images/bacon.png);
    border-radius: 20px;
    margin: 1px auto;
}