.Order {
    width: 100%;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 10px;
    margin: 10px auto;
    background-color: rgba(255, 255, 255, 0.8)
}

.Order button {
    float: right;
}