* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  background-image: url(./assets/images/table.jpeg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}