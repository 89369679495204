.BuildControls {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    padding: 10px 0;
}

.BuildControls .Price {
    color: red;
    text-shadow: 1px 1px 2px white, -1px -1px 2px white;
    font-size: 30px;
    font-weight: bold;
    margin: 0;
}

.OrderButton {
    background-color: gold;
    outline: none;
    cursor: pointer;
    border: 1px solid #966909;
    color: white;
    font-family: inherit;
    font-size: 1.0em;
    padding: 8px 10px;
    box-shadow: 2px 2px 2px #966909;
    border-radius: 20px
}

.OrderButton:hover,
.OrderButton:active {
    background-color: #A0DB41;
    border: 1px solid #966909;
    color: #966909;
}

.OrderButton:disabled {
    background-color: #C7C6C6;
    cursor: not-allowed;
    border: 1px solid #ccc;
    color: #888888;
}

.OrderButton:not(:disabled) {
    animation: enable 1.0s linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes enable {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}