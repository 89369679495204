.Burger {
    width: 100%;
    margin: 0 auto;
    height: 250px;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}


@media (min-width: 500px) and (min-height: 400px) {
    .Burger {
        width: 350px;
        height: 300px;
    }

    @media (min-width: 500px) and (min-height: 401px) {
        .Burger {
            width: 450px;
            height: 400px;
        }
    }

    @media (min-width: 1000px) and (min-height: 700px) {
        .Burger {
            width: 700px;
            height: 600px;
        }
    }
}